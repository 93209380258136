import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import Blog from './components/Blog';
import Footer from './components/Footer';
import PostDetail from './components/PostDetail';
import NotFound from './components/NotFound';
import About from './components/About';
import './App.css';
import './App.css';

const posts = [
  {
    id: 'post1',
    title: 'The 5 Best-Selling Items on Amazon Right Now',
    content: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
    author: 'chatGPT',
    date: 'September 9, 2024',
  },
  {
    id: 'post2',
    title: 'Post 2',
    content: 'This is the content of post 2.',
    author: 'Author 2',
    date: 'August 26, 2023',
  },
  {
    id: 'post3',
    title: 'Post 3',
    content: 'This is the content of post 3.',
    author: 'Author 3',
    date: 'August 26, 2023',
  },
  {
    id: 'post4',
    title: 'Post 4',
    content: 'This is the content of post 4.',
    author: 'Author 1',
    date: 'August 25, 2023',
  },
  {
    id: 'post5',
    title: 'Post 5',
    content: 'This is the content of post 5.',
    author: 'Author 2',
    date: 'August 26, 2023',
  },
  {
    id: 'post6',
    title: 'Post 6',
    content: 'This is the content of post 6.',
    author: 'Author 6',
    date: 'August 26, 2023',
  },
  // Add more posts here
];

const App = () => {
  return (
    <Router>
      <div className="app">
        <Header />
        <nav className="nav">
          <h3>Latest posts</h3>
          <ul>
            {posts.slice(-3).map((post) => (
              <li key={post.id}>
                <Link to={`/post/${post.id}`}>{post.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <Routes>
          <Route path="/" exact element={<Blog posts={posts} />} />
          <Route path="/about" exact element={<About posts={posts} />} />
          <Route path="/" exact element={<Blog posts={posts} />} />
          <Route path="/post/:postId" element={<PostDetail posts={posts} />} />
          <Route path="*" element={<NotFound posts={posts} />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
