import React from 'react';
import { useParams, Link } from 'react-router-dom';

const NotFound = ({ posts }) => {

    const { postId } = useParams();
  const post = posts.find((post) => post.id === postId);

  // Select three recommended posts (you can manually choose them)
  const recommendedPosts = [
    posts[1], // Second post
    posts[3], // Fourth post
    posts[5], // Sixth post
  ];
  return (
    <div className="not-found">
      <h2>Oops! Page Not Found</h2>
      <p>The page you're looking for doesn't exist.</p>
      <p>Here are some existing posts you might be interested in:</p>
      <div className="recommended-list">
          {recommendedPosts.map((recommendedPost) => (
            <div key={recommendedPost.id} className="recommended-post">
              <h4>{recommendedPost.title}</h4>
              <p>{recommendedPost.content.substring(0, 80)}...</p>
              <Link to={`/post/${recommendedPost.id}`}>Read More</Link>
            </div>
          ))}
        </div>
    </div>
  );
};

export default NotFound;