import React from 'react';
import { Link } from 'react-router-dom';

const PostPreviewCard = ({ post }) => {
  return (
    <div className="post-preview">
      <h3>{post.title}</h3>
      <p className="post-excerpt">{post.content.substring(0, 150)}...</p>
      <div className="post-info">
        <p className="author">By {post.author}</p>
        <p className="date">{post.date}</p>
      </div>
      <Link to={`/post/${post.id}`}>Read More</Link>
      <div className="ad ad-card">{/* ... (recommended posts section) */}</div>
    </div>
  );
};

const Blog = ({ posts }) => {
  return (
    <main>
      <div className="post-list">
        {posts.map((post, index) => (
          <PostPreviewCard key={index} post={post} />
        ))}
      </div>
    </main>
  );
};

export default Blog;
